import React from 'react';
import './App.css';
import Table from './Table';
import { createContext, useContext, useState } from 'react';
import Web3, { net } from 'web3';
import { BlockchainsContext, getBlockchains, TBlockchainsContext } from './Blockchains';


function App() {
  const [blockchains] = useState<TBlockchainsContext>(getBlockchains())

  return (<BlockchainsContext.Provider value={blockchains}>
    <div className='container-lg'>
      <h1>deBridge EVM dashboard</h1>
      <Table/>
    </div>
  </BlockchainsContext.Provider>);
}

export default App;
