import Web3 from "web3"
import { BlockchainsConfig } from "./BlockchainsConfig";
import { createContext, useContext } from "react";

export type TBlockchain = {
    name: string;
    chainId: number;
    web3: Web3
}

export function getBlockchains(): TBlockchain[] {
    return BlockchainsConfig.map(blockchain => ({
        ...blockchain,
        web3: new Web3(blockchain.rpc)
    }))
}

export type TBlockchainsContext = TBlockchain[]
export const BlockchainsContext = createContext<TBlockchainsContext>([]);

export const useGetBlockchains = () => useContext(BlockchainsContext);
