import React, { useEffect, useState } from 'react';
import './Table.css';
import { TBlockchain } from './Blockchains';
import { BlockchainContract, contracts } from './Contracts';

// Simple FNV-1a hash function for strings
function hashString(str: string): number {
    let hash = 0x811c9dc5;
    for (let i = 0; i < str.length; i++) {
        hash ^= str.charCodeAt(i);
        hash += (hash << 1) + (hash << 4) + (hash << 7) + (hash << 8) + (hash << 24);
    }
    return hash >>> 0; // Ensure the result is a positive number
}

// Function to convert a number to a HEX color
function numberToHexColor(num: number): string {
    const r = (num & 0xFF0000) >> 16;
    const g = (num & 0x00FF00) >> 8;
    const b = num & 0x0000FF;
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

// Function to generate a reproducible HEX color based on a string input
function generateHexColor(input: string): string {
    const hash = hashString(input);
    return numberToHexColor(hash);
}

export function ContractVersion({ blockchain, contract }: { blockchain: TBlockchain, contract: BlockchainContract }) {
    const [contractAddress, setContractAddress] = useState<string | undefined>()
    const [version, setVersion] = useState<string | undefined>();

    useEffect(() => {
        if (typeof contract.address === 'function')
            contract.address(blockchain.chainId, contracts, blockchain.web3)
                .then(v =>  {
                    setContractAddress(v)
                })
        else setContractAddress(contract.address)
    }, [contract.address])

    if (!contractAddress)
        return <>👀</>;

    if (!version) {
        const contractX = new blockchain.web3.eth.Contract(contract.abi, contractAddress);
        contractX.methods.version().call()
            .then(v => setVersion(`${v}`))
            .catch(e => {
                console.error(e)
                setVersion('❌')
            })
        return <>👀</>;
    }

    return (<span style={{
        color: generateHexColor(version)
    }}>{version}</span>)
}