export const BlockchainsConfig = [
    {
        name: 'Ethereum',
        chainId: 1,
        rpc: 'https://eth-mainnet.g.alchemy.com/v2/U8-K9SsFiYjvZYPCg7qLtGv6mFDtHoEJ'
    },
    {
        name: 'BNB',
        chainId: 56,
        rpc: 'https://bsc-dataseed.binance.org'
    },
    {
        name: 'Polygon',
        chainId: 137,
        rpc: 'https://polygon-mainnet.g.alchemy.com/v2/gNKMq2Ply1aTKW_dXzK8lagnvocGfdkO'
    },
    {
        name: 'Arbitrum',
        chainId: 42161,
        rpc: 'https://arb-mainnet.g.alchemy.com/v2/xJ1XL8nsxwuHTJFVx6GSfWQMa1tbPpyW'
    },
    {
        name: 'Avalanche',
        chainId: 43114,
        rpc: 'https://api.avax.network/ext/bc/C/rpc'
    },
    {
        name: 'Linea',
        chainId: 59144,
        rpc: 'https://rpc.linea.build/'
    },
    {
        name: 'Base',
        chainId: 8453,
        rpc: 'https://mainnet.base.org'
    },
    {
        name: 'Optimism',
        chainId: 10,
        rpc: 'https://opt-mainnet.g.alchemy.com/v2/XMSNHVTmkEdaiFt7ZEOw8eTN4vcE2QfJ'
    },
    {
        name: 'Neon',
        chainId: 245022934,
        rpc: 'https://neon-mainnet.everstake.one'
    },
    {
        name: 'Gnosis',
        chainId: 100,
        rpc: 'https://rpc.eu-central-2.gateway.fm/v4/gnosis/non-archival/mainnet'
    },
    {
        name: 'LL',
        chainId: 1890,
        rpc: 'https://replicator.phoenix.lightlink.io/rpc/v1'
    },
    {
        name: 'Metis',
        chainId: 1890,
        rpc: 'https://metis-mainnet.public.blastapi.io'
    },
    {
        name: 'Bitrock',
        chainId: 7171,
        rpc: 'https://connect.bit-rock.io'
    },
] as const