import Web3 from "web3";

export type BlockchainContract = {
    name: string;
    address: string | ((chain: number, contracts: BlockchainContract[], connection: Web3) => Promise<string>);
    abi: any;
}

export const contracts: BlockchainContract[] = [
    {
        name: 'deBridgeGate',
        address: async (chain: number) => {
            if (chain === 8453) return '0xc1656B63D9EEBa6d114f6bE19565177893e5bCBF'
            else return '0x43dE2d77BF8027e25dBD179B491e8d64f38398aA'
        },
        abi: [{
            "constant": true,
            "inputs": [],
            "name": "version",
            "outputs": [
                {
                    "name": "",
                    "type": "uint256"
                }
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"
        }] as const
    },
    {
        name: 'CallProxy',
        address: async (chain: number, contracts: BlockchainContract[], connection: Web3) => {
            const deBridgeGateAddressGetter = contracts.find(v => v.name === 'deBridgeGate')!.address
            const deBridgeGateAddress = typeof deBridgeGateAddressGetter === 'function' ? await deBridgeGateAddressGetter(chain, contracts, connection) : deBridgeGateAddressGetter;
            const res = await connection.eth.call({
                to: deBridgeGateAddress,
                data: connection.eth.abi.encodeFunctionCall(
                    {
                        name: 'callProxy',
                        type: 'function',
                        inputs: []
                    },
                    []
                )
            })

            return <string>connection.eth.abi.decodeParameter('address', res)
        },
        abi: [{
            "constant": true,
            "inputs": [],
            "name": "version",
            "outputs": [
                {
                    "name": "",
                    "type": "uint256"
                }
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"
        }] as const
    },
    {
        name: 'DlnSource',
        address: '0xeF4fB24aD0916217251F553c0596F8Edc630EB66',
        abi: [{
            "constant": true,
            "inputs": [],
            "name": "version",
            "outputs": [
                {
                    "name": "",
                    "type": "string"
                }
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"
        }] as const
    },
    {
        name: 'DlnDestination',
        address: '0xE7351Fd770A37282b91D153Ee690B63579D6dd7f',
        abi: [{
            "constant": true,
            "inputs": [],
            "name": "version",
            "outputs": [
                {
                    "name": "",
                    "type": "string"
                }
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"
        }] as const
    },
    {
        name: 'ExternalCallAdapter',
        address: async (chain: number, contracts: BlockchainContract[], connection: Web3) => {
            const deBridgeGateAddressGetter = contracts.find(v => v.name === 'DlnDestination')!.address
            const deBridgeGateAddress = typeof deBridgeGateAddressGetter === 'function' ? await deBridgeGateAddressGetter(chain, contracts, connection) : deBridgeGateAddressGetter;
            const res = await connection.eth.call({
                to: deBridgeGateAddress,
                data: connection.eth.abi.encodeFunctionCall(
                    {
                        name: 'externalCallAdapter',
                        type: 'function',
                        inputs: []
                    },
                    []
                )
            })

            return <string>connection.eth.abi.decodeParameter('address', res)
        },
        abi: [{
            "constant": true,
            "inputs": [],
            "name": "version",
            "outputs": [
                {
                    "name": "",
                    "type": "string"
                }
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"
        }] as const
    }
]
